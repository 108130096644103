import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leave-calendar',
  templateUrl: './leave-calendar.component.html',
  styleUrls: ['./leave-calendar.component.scss']
})
export class LeaveCalendarComponent implements OnInit {

  passwordInput = '';
  rememberMe = false;
  isAuthorized = false;

  private readonly password = 'splitcrow';
  private readonly passwordKey = 'saved-password';

  constructor() { }

  ngOnInit(): void {
    const savedPassword = localStorage.getItem(this.passwordKey);

    if (savedPassword === this.password) {
      this.isAuthorized = true;
    }
    else {
      this.isAuthorized = false;
    }

  }

  submitPassword() {
    this.isAuthorized = this.passwordInput === this.password;

    if (this.isAuthorized && this.rememberMe)
      localStorage.setItem(this.passwordKey, this.passwordInput);
    else
      localStorage.removeItem(this.passwordKey);

    if (!this.isAuthorized) {
      alert('Invalid password, please try again.');
      this.passwordInput = '';
    }

  }
}
