<main class="form-signin" *ngIf="!isAuthorized">

  <form action="">
    <h1 class="h3 mb-3 fw-normal">Please Enter Password</h1>
    <label for="inputPassword" class="visually-hidden" required autofocus>Password</label>
    <input type="password" id="inputPassword" class="form-control" name="password" placeholder="Password"
      [(ngModel)]="passwordInput" [ngModelOptions]="{standalone:true}" required>
    <div class="checkbox mb-3">
      <label>
        <input type="checkbox" name="rememberMe" [(ngModel)]="rememberMe" [ngModelOptions]="{standalone:true}"> Remember
        me
      </label>
    </div>
    <button class="w-100 btn btn-lg btn-primary" (click)="submitPassword()">Submit</button>
  </form>
</main>
<div *ngIf="isAuthorized">
  <iframe style="height: 1000px;" class="w-100" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRPAjbo7KiNxa80dFdJP6nnkUssVk22xmQap0RxCqHD9sitiQFUadDqimfIw_JirzFEHH-ZUAYY7m8D/pubhtml?gid=609254980&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
</div>