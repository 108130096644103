<header class="d-flex justify-content-between">
  <h1 class="m-3 pe-auto" routerLink="/">YHZ Tower</h1>

  <nav class="d-flex  my-3 m-5">
    <a class="nav-link" routerLink="/">Home</a>
    <a class="nav-link" routerLink="/leave-calendar">Leave Calendar</a>
    <a class="nav-link" routerLink="/leave-guide">Leave Guide</a>
  </nav>
</header>
<main class="container">
<router-outlet></router-outlet>
</main>
