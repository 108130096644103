<h2>Rounds</h2>
<ul class="list-group list-group-flush">
  <li class="list-group-item"><span class="fw-bold">Round #1</span><br>Pick 1 cycle unrestricted</li>
  <li class="list-group-item"><span class="fw-bold">Round #2</span><br>Pick 2 cycles, restricted to not more than 1 cycle in prime</li>
  <li class="list-group-item"><span class="fw-bold">Round #3</span><br>Pick 10 individual days with no restrictions</li>
  <li class="list-group-item"><span class="fw-bold">Round #4</span><br>Pick any remaining days with no restrictions</li>
</ul>
<br>
<p>
  Any portion of a cycle is considered a cycle. <br>
  If more than 50% of the cycle falls within prime time, its considered a prime pick.
</p>
<h4>Prime Time</h4>
<ul class="list-group list-group-flush">
  <li class="list-group-item">June 15 2021 to Sept 15 2021</li>
  <li class="list-group-item">Dec 18 2021 to Jan 03 2022</li>
  <li class="list-group-item">March 12 2022 to March 20 2022 (Spring Break)</li>
</ul>